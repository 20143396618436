<template>
  <fd-button
    class="btn main bordered ml-2"
    @click="$emit('download', setLoading)"
  >
    <span class="d-flex justify-content-center align-items-center">
      <spinner v-if="isDownloading" size="14px" color="white"></spinner>
      <i v-else class="fas fa-download"></i>
      <span class="d-none sm-d-inline-block ml-1">Download</span>
    </span>
  </fd-button>
</template>

<script>
export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      isDownloading: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    setLoading(isLoading) {
      this.isDownloading = isLoading;
    }
  }
};
</script>

<style lang="scss">
</style>
